<template>
  <section id="pf-asociar-lotes">
    <b-row>
      <b-col>
          <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <ag-grid-table ref="grid-list-mmpp" :configUrl="config1" :dataUrl="data1" :editUrl="edit1" rowSelection="single">
        </ag-grid-table>
      </b-col>
    </b-row>

  </section>
</template>
<script>
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EliminarActions from "./actions/EliminarActions.vue";
import RepeatStatistics from "@/views/clients/pf/components/RepeatStatistics";
import NumberCellEditor from "@/views/brunacomponents/ag-grid-table/cell-editors/NumberCellEditor"
import axios from "@axios";


export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButton,
    BrunaForm,
    ToastificationContent,
    EliminarActions: EliminarActions,
    RepeatStatistics,
    NumberCellEditor,

  },
  data() {
    return {
      config1: useApiServices.asociarLotesBrunaConfig,
      data1: useApiServices.asociarLotesBrunaData,
      edit1: useApiServices.asociarLotesBrunaEdit,
      itemsData: [],
    };
  },
  methods: {

    selectionLotesBrunaTable(rows) {
      this.formatData.selectBrunaTable = rows[0].meta_data.codigo;
      if (this.formatData.selectBrunaTable != null) {
        this.formatData.selected = true
      };

    },


    addNewMmpp() {
      let data = this.mmpp.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      // console.log({ data })
      Promise.all([
        this.$refs.mmpppRef.validate(),
      ]).then(([mmppValid]) => {
        if (mmppValid) {

          useApiServices.postMmppCreate(data)
            .then((response) => {
              console.log(response);

              this.$refs['grid-list-mmpp'].refreshRows()
              
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });

            })
            .catch((error) => {

              console.log(error)

              let message = error.response.data.message;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });


    }
  },
  mounted() {
    axios
            .get(useApiServices.historicoMateriaPrimaPfStatistics)
            .then((response) => {
                this.itemsData = response.data;
            })
            .catch((error) => { });
  },

};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}

.mt-3 {
  border: 1px solid green !important;
  background-color: green !important;
  color: white !important;
}

.mt-3:hover {

  transition: 0.3s ease-in !important;
  border: 1px solid green !important;
  background-color: transparent !important;
  color: green !important;
  color: white;
}

.mt-2 {
  border: 1px solid red !important;
  background-color: red !important;
  color: white !important;
}

.mt-2:hover {

  transition: 0.5s ease-in !important;
  border: 1px solid red !important;
  background-color: transparent !important;
  color: red !important;
}
</style>